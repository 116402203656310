import { Link } from "gatsby"
import React from "react"
import { Helmet } from "react-helmet"

const Header = () => (
  <header className="header-container">
    <Helmet>
      <meta charSet="utf-8"/>
      <title>Hampus Backman</title>
    </Helmet>

    <div className="site-title">
      <Link className="site-title--link" to="/">
        Hampus Backman
      </Link>
    </div>
    <div className="navigation">
    </div>
  </header>
);

/*
<div className="navigation">
  <div className="navigation--item">
  </div>
</div>
 */

export default Header;